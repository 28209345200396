<template>
    <div class="result second-box" id="scrollbox">
        <Head :title="$t('common.jiexiao')" :right="true" :show="true">
            <img @click="gotoQuestion" src="../../assets/imgs/rules.png" class="rules-icon" alt="">
            <!-- <img @click="gotoResultList" src="../../assets/imgs/shai.png" class="shai-icon" alt=""> -->
        </Head>
        <div class="main-box result-box">
            <div class="nav-main">
                <van-tabs :sticky="true" :animated="true" :swipeable="true" v-model="active" class="mytabs">
                    <van-tab title="Cloud Bid" name="1">
                        <CBList :listadd="listadd" :refresh="refresh"  :cb="true" :items="items" ></CBList>
                    </van-tab>
                    <van-tab title="Time Twister" name="2">
                        <CBList :listadd="listadd" :refresh="refresh" :tt="true"  :items="items" ></CBList>
                    </van-tab>
                </van-tabs>
            </div>
        </div>

        <PlayRule ref="play" :changescroll="changescroll" ></PlayRule>
    </div>
</template>

<script>

export default {
    name:'result',
    data(){
        return {
            active:this.$route.query.active || "1",
            items:[],
            cblist:[],
            ttlist:[],
            flag:false,
            page:1,
            pageSize:50,
            total:0,  
            nowActive:"1",
            good_id:""
        }
    },
    watch:{
        active(v){
            this.page = 1;
            if(this.nowActive == v){
                this.good_id = this.$route.query.good_id
            }else{
                this.good_id = ""
            }
            this.$ajax.winningHistory({
                page:this.page,
                pageSize:this.pageSize,
                active_id:v,
                good_id:this.good_id
            }).then(res=>{
                if(res.code==200){
                    this.items =  res.data.rstArr
                    this.total  = res.data.tpage.total;
                }
            })
        }
    },
    mounted(){
        if(this.$route.query.active){
            this.nowActive =  this.$route.query.active 
        }
       
        // 简单判断  
        // if(this.$route.query.good_id){
            
        // }else{
        //     this.$ajax.winningHistory({
        //         page:this.page,
        //         pageSize:this.pageSize,
        //         active_id:this.active
        //     }).then(res=>{
        //         if(res.code==200){
        //             this.items = res.data.rstArr;
        //             this.total  = res.data.tpage.total;
        //         }
        //     })
        // }
        this.$ajax.winningHistory({
            page:this.page,
            pageSize:this.pageSize,
            active_id:this.active,
            good_id:this.$route.query.good_id
        }).then(res=>{
            if(res.code==200){
                this.items = res.data.rstArr;
                this.total  = res.data.tpage.total;
            }
        })
    },
    components:{
        
    },
    methods:{
        refresh(callback){
            this.page = 1;
            if(this.nowActive == this.active){
                this.good_id = this.$route.query.good_id
            }else{
                this.good_id = ""
            }
            this.$ajax.winningHistory({
                page:this.page,
                pageSize:this.pageSize,
                active_id:this.active,
                good_id:this.good_id
            }).then(res=>{
                if(res.code==200){
                    this.items = res.data.rstArr;
                    this.total  = res.data.tpage.total;
                }
                callback();
            })
        },
        listadd(callback,cbend){
            if(this.page<Math.ceil(this.total/this.pageSize)){
                this.page++;
                if(this.nowActive == this.active){
                    this.good_id = this.$route.query.good_id
                }else{
                    this.good_id = ""
                }
                this.$ajax.winningHistory({
                    page:this.page,
                    pageSize:this.pageSize,
                    active_id:this.active,
                    good_id:this.good_id
                }).then(res=>{
                    if(res.code==200){
                        this.items = this.items.concat(res.data.rstArr);
                        this.total  = res.data.tpage.total;
                    }
                    callback();
                })
            }else{
                cbend()
            }
        },
        changescroll(flag){
            if(flag){
                document.getElementById('scrollbox').classList.add('vux-modal-open');
                }else{
                document.getElementById('scrollbox').classList.remove('vux-modal-open');
            }
        },
        gotoQuestion(){
            this.$refs.play.show = true;
        },
        gotoResultList(){
            this.$router.push({
                name:"main-resultShai"
            })
        }
    }
}
</script>